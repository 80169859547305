@import '../../styles/colors';

.container {
    display: flex;
    flex-direction: row;
    flex: 1;

    border: 1px solid $SoftGrey;
    border-radius: 7px;
    padding: 10px;
    margin: 10px 0;
    cursor: pointer;
    transition: border .25s ease, box-shadow .25s ease;

    &:hover {
        border: 1px solid $PrimaryRed;
        box-shadow: 1px 1px 5px #a7a7a7;
    }
}

.imageContainer {
    margin-right: 5px;
}

.image {
    height: 125px;
    width: 125px;

    // Small devices (landscape phones, less than 768px)
    @media (max-width: 991.98px) {
        height: 100px;
        width: 100px;
    }
}

.details {
    flex-direction: column;
}

.restaurantName {
    font-size: 24px;
    color: $PrimaryText;
}

.description {
    display: block;
    color: $SecondaryText;
    font-size: 16px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    width: calc(70vw - 75px); //This is a really magic number that happens to work
}

.location {
    font-size: 16px;

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
        font-size: 14px;
    }
}

.openTime {
    color: green;
}

.closeTime {
    color: $RedStrong;
}
