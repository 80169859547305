
$grey3: #86939e;

$PrimaryRed: #E04341;
$RedStrong: #9E1309;
$RedLight: #F78383;
$RedLightTransparent: #F7838377;

$BluePrimary: #274487; //Oxford Blue
$BlueLight: #5670AC;

$YellowLight: #FFD42F;
$YellowPrimary: #F8A927;
$YellowStrong: #EF781A;

$JET: #323031;
$METALLIC_SEAWEED: #177E89;
$AccentGreen: $METALLIC_SEAWEED;

$PrimaryText: black;
$SecondaryText: #A7A7A7;

$SoftGrey: #A7A7A7;

$ListItemBorder: #A7A7A799;


$BackgroundColor: #F2F2F2;

//3rd Party Colors
$Facebook_Blue: #3b5998;
$Facebook_Blue_Hover: #0e1f56;
$Google_Blue: #4285F4;
$Google_Blue_Hover: darken($Google_Blue, 10);

/***

const grey3 = '#86939e';
const RedPrimary = '#E04341';   //Vermillion
const RedStrong = '#9E1309';
const RedLight = '#F78383';
const RedLightTransparent = '#F7838377';

const BluePrimary = '#274487'; //Oxford Blue
const BlueLight = '#5670AC';

export const BackgroundContrast = '#EFEFEF';

export const JET = '#323031';
export const METALIC_SEAWEED = '#177E89';
export const YELLOW = '#FFC857';

export const YellowLight = '#FFD42F';
export const YellowPrimary = '#F8A927';
export const YellowStrong = '#EF781A';

export const AccentGreen = METALIC_SEAWEED;

export { grey3 };
export { BluePrimary, BlueLight };
export { RedPrimary, RedStrong, RedStrong as RedHighlight, RedLight, RedLightTransparent };


export const TextPrimary = 'black';
export const TextSecondary = '#A7A7A7';

export const PrimaryBackgroundColor = 'white';

export const PlaceholderTextColor = TextSecondary;

//Header
export const OwnerHeaderColor = BluePrimary;
export const OwnerHeaderTextColor = 'white';

export const ConsumerHeaderColor = RedPrimary;
export const ConsumerHeaderTextColor = 'white';

//Buttons
export const ConsumerButtonTextColor = 'white';
export const ConsumerButtonColor = BluePrimary;
export const ConsumerButtonDisabledColor = BlueLight;

export const OwnerButtonTextColor = 'white';
export const OwnerButtonColor = BluePrimary;
export const OwnerButtonDisabledColor = BlueLight;

//Loading
export const LoadingBackground = '#F5FCFF88';
export const LoadingIndicator = BluePrimary;


//ListItem
export const ListItemBorder = '#A7A7A799';


//Divider
export const DividerHairLineColor = '#BCBBC1';
export const DividerHairLineColorAlt1 = 'rgba(0, 0, 0, 0.12)';

 */
