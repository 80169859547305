@import '../../styles/colors';

.container {
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  width: 100%
}

.contentContainer {
  flex: 1;
  flex-direction: row;
  display: inline-flex;
  width: 100%;
}

.quantityContainer {
  width: 40px;
  text-align: center;
}

.quantity {
  align-self: center;
  margin-left: 10px;
  font-size: 20px;
}

.titleContainer {
  flex: 4;
  justify-content: center;
  margin-left: 10px;
}

.title {
  font-size: 16px;
  color: $PrimaryText;
  font-weight: bold;
}

.subtitle {
  font-size: 12px;
  margin-left: 0;
  color: $SecondaryText;
  font-weight: 300;
}

.itemEdit {
  margin-left: 10px;
  align-self: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.priceContainer {
  flex: 1;
  justify-content: center;
}

.price {
  align-self: flex-end;
  margin-right: 10px;
  text-align: right;
  font-weight: 300;
}

.delete {
  color: $PrimaryRed;
  text-align: center;
  cursor: pointer;

  &h:hover {
    text-decoration: underline;
  }
}
