@import '../styles/colors';

.button {
  background-color: $PrimaryRed;
  border-color: $PrimaryRed;

  transition: background-color .25s ease,  border-color .25s ease;

  &:hover {
    background-color: $RedStrong;
    border-color: $RedStrong;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus {
    background-color: $RedStrong;
    border-color: $RedStrong;
    box-shadow: none;
  }

  &:disabled {
    cursor: pointer;
    opacity: .25;
    background-color: $PrimaryRed;
    border-color: $PrimaryRed;
  }
}

.outlineButton {
  color: $PrimaryRed;
  background-color: transparent;
  border-color: $PrimaryRed;

  transition: background-color .25s ease,  border-color .25s ease;

  &:hover {
    background-color: $RedStrong;
    border-color: $RedStrong;
    color: white;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus {
    background-color: $RedStrong;
    border-color: $RedStrong;
    box-shadow: none;
  }

  &:disabled {
    cursor: pointer;
    opacity: .25;
    color: $PrimaryRed;
    background-color: transparent;
    border-color: $PrimaryRed;
  }
}
