@import '../../styles/colors';
@import '../../styles/layouts';

$totalWidth: 300px;
$collapsedWidth: 70px;

/******* Mobile Order Bar *******/

.mobileContainer {
  background-color: white;

  //RGR removing this solves lots of problems...I dont know what keeping it in does...
  //height: 100%;
  width: 100%;
  z-index: 110;
}

.mobileMainRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid $SoftGrey;
  height: $orderBarHeight;
  position: fixed;
  width: 100%;
  z-index: 90;
  padding-right: 5px;
  background: white;
}

.mobileItem {
  margin-right: 5px;
}

.drawerMobileContainer {
  position: fixed;
  background-color: white;
  transform: translateX(101%);
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - #{$orderBarHeight + $headerHeight});
  z-index: 80;
  padding-bottom: 70px;
  padding-top: 30px;
  @media (max-width: 575.98px) {
    margin-top: $orderBarHeight;
  }
}

/****** Full Size Order Bar *******/

.container {
  background-color: white;
  transform: translateX(#{$totalWidth - $collapsedWidth});
  border-left: 1px solid $SoftGrey;
  position: fixed;
  top: $headerHeight;
  right: 0;
  height: calc(100vh - #{$headerHeight});
  width: $totalWidth;
  z-index: 80;

  //box-shadow: -1px 0px 20px 0px #86939eFF;
}

.mainRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: $collapsedWidth;
}



.shoppingCartIcon {
  background-color: $PrimaryRed;
  border-radius: 7px;
  margin: 5px;
  cursor: pointer;
  transition: box-shadow .1s ease;

  &:hover {
    box-shadow: 1px 1px 10px 5px $RedLightTransparent;
  }
}

.icon {
  margin: 5px;
  color: $RedLight;
}

.itemCountContainer {
  width: $collapsedWidth;
}

.itemText {
  text-align: center;
}

.costContainer {
  width: $collapsedWidth;
}

.drawerContainer {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-right: 10px;
}

$height: $orderBarTopRowHeight + $headerHeight + 60px /*Footer*/ + 56px /*Checkout Button + Margin */;

.menuItemContainer {
  margin-top: 20px;
  width: 100%;
  height: calc(100vh - #{$height});
  overflow-x: scroll;
}

.bottomShadow {
  box-shadow: 3px -1px 6px 2px $grey3;
  height: 19px;
  width: 100%;
}

.badge {
  background-color: $AccentGreen;
}

.checkoutButton {
  width: 80%;
}

.buttonTextContainer {
  display: inline-flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
}

.buttonTextElement {
  display: flex;
  flex: 1;
}

.lastButtonTextElement {
  justify-content: flex-end;
  font-weight: 300;
}

.breaker {
  width: 80%;
}

.addMoreButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  color: $PrimaryRed;
  cursor: pointer;
}
