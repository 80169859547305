@import '../../styles/colors';
@import '../../styles/layouts';

.navbar {
    background-color: $PrimaryRed;
    position: fixed;
    height: $headerHeight;
    color: white;
    flex-shrink: 0;
    width: 100%;
    z-index: 100;

    a {
        color: white;
    }
}

.brandImage {
    width: 137px;
    height: 40px;
}

.navbarCollapse {
    background: $PrimaryRed;
    color: white;
    z-index: 100;

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 767.98px) {
        border: .5px solid white;
    }
}


.dropdown {
    color: white !important;
}

.navItem a {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    padding: 5px 20px !important;
    font-size: 18px;
}

.navItemIcon {
    font-size: 20px;
    padding: 0 5px;
}
