@import '../../styles/colors';
@import '../../styles/layouts';

.mainContainer {
    flex: 1 0 auto;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    margin-top: $headerHeight;
    background-color: $BackgroundColor;
}


.contentContainer {
    flex: 1 0 auto;
    padding-left: 0;
    padding-right: 0;
    height: 100%;

    @media (max-width: 575.98px) {
        margin-top: $orderBarHeight;
    }
}
