@import '../../styles/colors';


.selectableOption {
  flex-direction: row;
  display: flex;
  align-items: center;

  border: 1px solid $grey3;
  border-radius: 7px;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 5px;

  &:hover {
    border-color: $PrimaryRed;

    .icon {
      color: $PrimaryRed;
    }
  }


}

.icon {

  &.selected {
    color: $PrimaryRed;
  }
}

.title {
  margin-left: 5px;
}

.price {
  justify-content: flex-end;
  flex: 1;
  display: inline-flex;

  color: $SecondaryText;
  font-size: 16px;
}
