@import '../../styles/colors';



.modalContent {
  height: 90vh;
}

.modalBody {
  height: 70vh;
  overflow-x: scroll;
}

.title {
  font-size: 24px;
  font-weight: 300;
  color: $PrimaryText;
}

.description {
  font-size: 20px;
  font-weight: 300;
  color: $SecondaryText;
}

.specialInstructions {
  margin-top: 20px;
  font-size: 20px;
  color: $PrimaryText;
}

.quantity {
  margin-right: 15px;
}

.modalHeaderScrollable {
  box-shadow: 1px 1px 10px grey;
  z-index: 50;
}

.modalFooterScrollable {
  box-shadow: 1px 1px 10px grey;
  z-index: 50;
}

.errorMessage {
  color: red;
  font-size: 12px;
}
