@import '../../styles/colors';


$MatchTitleMarginBottom: 2px;

.sectionTitleContainer {
  flex: 1;
}

.titleContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;

  margin-bottom: 10px;
  border-bottom: 1px solid $grey3;
}
.textStyle {
  font-size: 20px;
  color: $PrimaryText;
}

.errorTextStyle {
  font-size: 16px;
  color: red;
}
.subtitleStyle {
  color: $SecondaryText;
  align-self: flex-end;
  font-size: 12px;
  margin-left: 5px;

  margin-bottom: $MatchTitleMarginBottom
}
.textStyleFullBuffer {
  margin-top: 30px
}

.textStyleHalfBuffer {
  margin-top: 15px
}
.badgeContainer {
  flex: 1;
  justify-content: flex-end;
  text-align: right;
}
.badge {
  margin-bottom: $MatchTitleMarginBottom
}
.badgeRequired {
  background-color: $PrimaryRed
}

.badgeOptional {
  background-color: $AccentGreen
}

.badgeText {
  color: white;
  font-size: 14px
}
