
@import '../../styles/layouts';

.jumbo {
  color: white;
  border-radius: 0 0 $BORDER_RADIUS $BORDER_RADIUS;
}

//Used to get around Algolia's styling
:global {
  .ais-SearchBox-input {
    font-size: 22px;
  }

  .ais-InstantSearch__root {
    height: 100%;
    background-color: white;
  }
}
