

.container {
  display: inline-flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}

.displayValue {
  width: 40px;
  text-align: center;
  font-size: 20px;
  user-select: none;
}

.clickable {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    opacity: .5;
    user-select: none;
  }
}
