@import '../../styles/colors';

.footer {
  flex-shrink: 0;
  width: 100%;
  min-height: 60px;
  background-color: white;
  border-top: 1px solid #A7A7A7;
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 90;

  @media (max-width: 767.98px) {
    padding-left: 15px;
  }
}

.innerContainer {
  display: inline-flex;
}

.navLinks {
  margin-right: 100px;
}

.footerLink {
  cursor: pointer;
  color: $SecondaryText;
  transition: color 0.25s ease;
  margin-top: 5px;

  &:hover {
    color: $PrimaryText;
    text-decoration: underline;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}
