@import '../../styles/colors';

.searchResultString {
  font-weight: bold;
  font-size: 18px;
  color: $PrimaryText;
}

.noResultsText {
  font-weight: bold;
}

.resultsContainer {
  margin: 0 15px;
  padding-bottom: 15px;
}

.clearSearch {
  cursor: pointer;
  font-weight: 300;
  margin-left: 20px;
  font-size: 14px;
  color: $SecondaryText;
  text-decoration: underline;
  transition: color 0.25s ease;
  padding: 5px;

  &:hover {
    color: $PrimaryText;
  }
}
