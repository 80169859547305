@import '../../styles/colors';
@import '../../styles/layouts';


.label {
  margin-bottom: 2px;
  font-size: 18px;
}

.input {
  color: $PrimaryText;
  border-radius: $BORDER_RADIUS;

  &:focus {
    color: $PrimaryText;
    border-color: $PrimaryText;
    box-shadow: none;
  }
}
